/* General Reset Password Page Styles */
html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", Arial, sans-serif;
    background-color: #f4f6f9;
    color: #333;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Root Container */
.reset-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Navbar Styling */
.reset-navbar {
    background-color: #6482AD;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.reset-navbar img {
    height: 50px;
}

.reset-navbar p {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
}

/* Reset Password Container */
.reset-password-frame {
    max-width: 600px;
    min-height: 400px;
    margin: auto;
    padding: 40px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Title and Description */
.reset-password-frame h3 {
    font-size: 2rem;
    color: #1d3557;
    margin-bottom: 15px;
}

.reset-password-frame p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
}

/* Input Group Styling */
.input-group {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input-group .input-group-text {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    color: #6c757d;
}

.input-group input {
    width: 100%;
    max-width: 400px;
    padding: 12px 15px 12px 45px;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
}

.input-group input:focus {
    outline: none;
    border-color: #457b9d;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(69, 123, 157, 0.5);
}

/* Error Messages */
/* Error Messages */
.error-messages {
    text-align: center; /* Align center if necessary */
    color: #e63946; /* Error color */
    font-size: 0.9rem;
    margin: 5px 0; /* Add margin to separate it from input fields */
    display: block; /* Ensure it spans the full width */
    position: relative; /* Ensures proper placement */
}

/* Button Styles */
button {
    width: 100%;
    max-width: 400px;
    margin: 15px auto;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-success {
    background-color: #2a9d8f;
    color: white;
}

.btn-success:hover {
    background-color: #21867a;
    transform: translateY(-2px);
}

.btn-secondary {
    background-color: #adb5bd;
    color: white;
}

.btn-secondary:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Success and Failure Messages */
.message-div {
    margin: auto;
    padding: 20px;
    text-align: center;
}

.text-success {
    color: #2a9d8f;
    font-size: 1.4rem;
}

.text-danger {
    color: #e63946;
    font-size: 1.4rem;
}

.message-div a {
    color: #1d3557;
    text-decoration: underline;
}

/* Footer Styling */
footer {
    margin-top: auto; /* Pushes the footer to the bottom */
    padding: 20px;
    background-color: #6482AD;
    color: white;
    text-align: center;
    font-size: 0.9rem;
    width: 100%; /* Ensures the footer spans the full width */
}

footer a {
    color: #a8dadc;
    text-decoration: none;
    transition: color 0.3s ease;
}

footer a:hover {
    color: #f1faee;
}

/* Responsive Design */
@media (max-width: 768px) {
    .reset-password-frame {
        padding: 20px;
        margin-top: 60px;
        height: auto;
    }

    .reset-password-frame h3 {
        font-size: 1.6rem;
    }

    .reset-navbar {
        padding: 10px 20px;
    }

    .reset-navbar p {
        font-size: 1.4rem;
    }

    .input-group input {
        padding: 10px 12px 10px 40px;
    }

    button {
        font-size: 0.9rem;
    }
}
