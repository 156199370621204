/* Global Footer Styles */
.foot {
    background-color: #333333;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    color: white;
    gap: 20px; /* Adds space between sections */
}

/* Links Section */
.left-content {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Vertically align items */
}

.links {
    display: flex; /* Align links horizontally */
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px; /* Add space between links */
}

.links li {
    margin: 0; /* Remove unnecessary margins */
}

.links a {
    color: white;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.links a:hover {
    color: #f41969; /* Highlight effect on hover */
    text-decoration: underline;
}

/* Copyright Section */
.right-content {
    text-align: center;
    font-size: 12px;
    line-height: 1.4;
}

.right-content p {
    margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .foot {
        flex-direction: column; /* Stack sections vertically */
        align-items: center;
        text-align: center;
        gap: 20px; /* Ensure consistent spacing */
    }

    .left-content {
        margin-bottom: 10px; /* Add spacing for stacked layout */
    }
}
