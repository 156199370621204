.accountDeletionConfirmation .message {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: large;
}

.accountDeletionConfirmation-footer {
    background-color: #333333;
    bottom: 0;
    display: table;
    height: 50px;
    position: absolute;
    width: 100%;
}

.accountDeletionConfirmation-footer .footmessage {
    color: white;
    display: table-cell;
    vertical-align: middle;
    font-size: medium;
    text-align: center;
}

.account-deletion-wrapper {
    margin: 0 auto;
    max-width: 700px;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    font-size: 24px;
    padding: 20px 15px;
}

.account-main-text {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 700;
}

.account-sub-text {
    font-size: 18px;
}

.accountDeletionConfirmation-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
}

.accountDeletionConfirmation-navbar img {
    position: absolute;
    left: 20px;
}

.accountDeletionConfirmation-navbar p {
    margin: 0;
    padding: 0;
}

.close-button {
    background: #ff5c5c;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 8px;
    border-radius: 5px;
    margin-top: 20px;
    transition: background 0.3s ease;
}

.close-button:hover {
    background: #e04a4a;
}
