/* Ensure full height for HTML and Body */
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Home Container: Flexbox layout for main content */
.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Main Content */
.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    padding: 20px;
}

.logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.main-title {
    font-size: 2.5rem;
    color: #333;
    margin: 10px 0;
}

.main-description {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto 30px;
    line-height: 1.5;
}

/* Credits Section */
.credits-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    margin-top: 30px;
    width: 100%;
}

.credits-text {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
}

.credit-title {
    font-size: 1.8rem;
    color: #f41969;
    margin-bottom: 10px;
    text-align: left;
}

.credit-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    text-align: justify;
}

.credits-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
}

.credits-image img {
    width: 100%;
    max-width: 450px;
    height: auto;
    object-fit: contain;
}

/* Footer */
footer {
    background-color: #333;
    color: white;
    padding: 15px;
    text-align: center;
    font-size: 0.9rem;
    width: 100%;
    margin-top: auto;
    position: relative;
}

/* Links in Footer */
footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-weight: bold;
    transition: color 0.3s ease;
}

footer a:hover {
    color: #f41969;
}

/* Responsive Design */
@media (max-width: 800px) {
    .credits-section {
        flex-direction: column;
    }

    .credits-text,
    .credits-image {
        max-width: 90%;
    }

    .credits-image img {
        max-width: 100%;
    }
}
