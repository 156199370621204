.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #6482AD; /* Background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.navbar-logo img {
    height: 50px; /* Adjust the size of the logo */
}

.navbar-title {
    flex-grow: 1; /* Ensures the title is centered between the logo and any other elements */
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}
