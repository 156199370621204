


.account-deletion-page .account-deletion-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
    background-color: #343a40;
    color: white;
    position: relative;
}

.account-deletion-page .account-deletion-navbar .navbar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.account-deletion-page .account-deletion-navbar .navbar-logo {
    height: 80px;
    padding: 10px;
    position: absolute;
    left: 20px;
}

.account-deletion-page .account-deletion-navbar p {
    margin: 0;
    padding: 0;
}

.account-deletion-page .footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.account-deletion-page .footmessage p {
    margin: 0;
    font-size: 14px;
}

/*.account-deletion--container {*/
/*    max-width: 600px;*/
/*    margin: 100px auto 50px auto;*/
/*    padding: 20px;*/
/*    border-radius: 10px;*/
/*    background-color: #EEEEEE;*/
/*    box-shadow: 0 0 10px rgba(0,0,0,0.1);*/
/*    text-align: center;*/
/*}*/



.account-deletion--container {
    max-width: 500px;
    margin: 20px auto 30px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #EEEEEE;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    text-align: center;
}




.account-deletion--header {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.account-deletion--warning {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: left;
}

.account-deletion--warning ul {
    list-style-type: disc;
    margin-left: 20px;
}

.account-deletion--form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    text-align: left;
}

.account-deletion--form-group {
    margin-bottom: 20px;
}

.account-deletion--form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.account-deletion--form-group input,
.account-deletion--form-group textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.website-logo {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 200px;
}

.account-deletion--form-group button {
    background-color: #ff5c5c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 25px;
}

.account-deletion--form-group button:hover {
    background-color: #e04a4a;
}

.radio-group {
    display: flex;
    flex-direction: column;
}

.radio-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio-item input[type="radio"] {
    margin-right: 10px;
    accent-color: #4caf50;
    width: 20px;
    height: 20px;
}

.radio-item label {
    margin-left: 5px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

h6 {
    display: block;
    font-size: 1em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
}
